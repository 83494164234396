.contact-page {
  min-height: 100vh;
  padding-top: 22vmin;
}

body.dark .contact-page {
  background-color: var(--dark);
}

.contact-options {
  font-size: 9vmin;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
}

.contact-text {
  color: var(--off-dark);
  font-size: 4vmin;
}

.profile-pic img {
  max-height: 42vmin;
  border-radius: 5vmin;
  border: 0.4vmin solid var(--off-dark);
}

body.dark .contact-text {
  color: var(--off-white)
}

body.dark .profile-pic img {
  border-color: var(--off-white);
}

@media (min-width: 768px) {
  .contact-page {
    padding-top: 15vmin;
  }
  .contact-options {
    flex-direction: row;
  }
  .contact-text {
    font-size: 2.8vmin;
  }
  .profile-pic img {
    max-height: 35vmin;
  }
}
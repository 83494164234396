*, *:before, *:after {
  box-sizing: border-box;
}

:root {
  --white: rgb(255, 255, 255);
  --black: rgb(0, 0, 0);
  --dark: rgb(18, 18, 18);
  --off-white: rgb(241, 241, 241);
  --off-dark: rgb(30, 31, 39);
  --blue-light: rgb(42, 41, 234);
  --blue-dark: rgb(64, 156, 255);
  --cyan-dark: rgb(112, 215, 255);
  --purple-light: rgb(85, 41, 234);
  --purple-dark: rgb(218, 143, 255);
}

html {
  height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'League Spartan', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--off-white);
  color: var(--dark);
  height: 100%;
  overflow-x: hidden;
}

body.dark {
  background-color: var(--off-dark);
  color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-weight: bold;
}

.heading-underline {
  text-decoration: underline var(--purple-light) !important;
  -webkit-text-decoration-line:  underline !important;
  -webkit-text-decoration-color: var(--purple-light) !important;
  text-underline-offset: 0.5rem !important;
}

body.dark .heading-underline {
  text-decoration: underline var(--purple-dark) !important;
  -webkit-text-decoration-line:  underline !important;
  -webkit-text-decoration-color: var(--purple-dark) !important;
}
.projects-page {
  min-height: 100vh;
  padding-top: 22vmin;
}

body.dark .projects-page {
  background-color: var(--dark);
}

.projects-page .badge, .project-description {
  font-size: 4vmin;
}

.projects-page .badge {
  background-color: rgba(85, 41, 234, 0.7) !important;
  color: var(--white);
}

body.dark .projects-page .badge {
  background-color: rgba(218, 143, 255, 0.7) !important;
  color: var(--dark);
}

.projects-page .badge:hover {
  background-color: var(--purple-light) !important;
}

body.dark .projects-page .badge:hover {
  background-color: var(--purple-dark) !important;
}


.title-and-image img {
  max-height: 50vmin; 
}

.title-and-image {
  color: var(--purple-light);
}

body.dark .title-and-image {
  color: var(--purple-dark);
}

.links-to-site {
  font-size: 8vmin;
}

@media (min-width: 500px) {
  .projects-page .badge {
    font-size: 3vmin;
  }
  .links-to-site {
    font-size: 6vmin;
  }
  .title-and-image img {
    max-height: 28vmin;
  }
}

@media (min-width: 768px) {
  .projects-page {
    padding-top: 15vmin;
  }
  .project-description {
    font-size: 2.8vmin;
  }
  .projects-page .badge {
    font-size: 2.5vmin;
  }
  .links-to-site {
    font-size: 6vmin;
  }
  .title-and-image img {
    max-height: 25vmin;
  }
}

@media (min-width: 992px) {
  .title-and-image img {
    max-height: 30vmin; 
  }
}

@media (min-width: 1024px) {
  .title-and-image img {
    max-height: 32vmin; 
  }
}

@media (min-width: 1200px) {
  .title-and-image img {
    max-height: 35vmin; 
  }
}
nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  background-color: var(--white);
  background-image: linear-gradient(90deg, var(--purple-light) 20%, var(--off-white) 50%, var(--white) 100%);
  background-image: linear-gradient(135deg, var(--purple-light) 15%, rgba(85, 41, 234, 0.7) 30%, rgba(85, 41, 234, 0.4) 40%, var(--off-white) 50%, var(--white) 100%);
}

body.dark nav {
  background-color: var(--off-dark);
  background-image: linear-gradient(90deg, var(--purple-dark) 20%, var(--off-dark) 50%, var(--dark) 100%);
  background-image: linear-gradient(135deg, var(--purple-dark) 15%, rgba(218, 143, 255, 0.7) 30%, rgba(218, 143, 255, 0.4) 40%, var(--off-dark) 50%, var(--dark) 100%);
}

#theme-large-screen {
  display: none;
}

nav a, nav .theme-toggle {
  padding: 2vmin 2vmin;
  text-decoration: none;
  color: var(--off-dark);
}

nav a {
  font-size: 4.5vmin;
}

nav .theme-toggle {
  display: flex;
  align-items: center;
  font-size: 6vmin;
}

.nav-name {
  color: var(--white);
  font-size: 8vmin;
}

body.dark .nav-name {
  color: var(--dark);
}

.letter-c {
  margin-left: -2vmin;
  margin-top: 3.3vmin;
}

nav .navbar-toggler {
  color: var(--dark-grey-2);
  border: none;
}

body.dark nav a, body.dark .theme-toggle, body.dark .navbar-toggler {
  color: var(--off-white);
}

nav .navbar-link:hover, body .theme-toggle:hover, .hamburger-react:hover {
  cursor: pointer;
  color: var(--purple-light);
  text-decoration: underline var(--purple-light);
  text-underline-offset: 0.3em;
}

body.dark nav .navbar-link:hover, body.dark .theme-toggle:hover, body.dark .hamburger-react:hover {
  color: var(--purple-dark);
  text-decoration: underline var(--purple-dark);
}

.nav-name:hover svg {
  color: var(--purple-light);
  fill: var(--white);
}

body.dark .nav-name:hover svg {
  color: var(--purple-dark);
  fill: var(--dark);
}

@media (min-width: 768px) {
  nav, body.dark nav {
    background-image: none;
  }
  nav a {
    font-size: 2.8vmin;
  }
  nav .theme-toggle {
    font-size: 3.5vmin;
  }
  .nav-name {
    color: var(--purple-light);
    font-size: 6vmin;
  }
  .nav-name:hover svg {
    color: var(--dark);
  }
  body.dark .nav-name {
    color: var(--purple-dark);
  }
  body.dark .nav-name:hover svg {
    color: var(--white);
  }
  .letter-c {
    margin-left: -1.5vmin;
    margin-top: 2.5vmin;
  }
  #theme-small-screen {
    display: none;
  }
  #theme-large-screen {
    display: block;
  }
}

@media (min-width: 1024px) {
  nav a {
    font-size: 3.2vmin;
  }
  nav .theme-toggle {
    font-size: 4vmin;
  }
}
.experience-page {
  min-height: 100vh;
  font-size: 4vmin;
  padding-top: 22vmin;
  background-color: var(--white);
}

body.dark .experience-page {
  background-color: var(--off-dark);
}

.work-history-list {
  list-style: none;
}

.employer-name {
  color: var(--purple-light);
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  font-size: 5vmin;
}

body.dark .employer-name {
  color: var(--purple-dark);
}

.role-and-dates {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.role {
  display: flex;
  justify-content: flex-start;
}

.role-dates {
  color: rgba(85, 41, 234, 0.8);
}

body.dark .role-dates {
  color: rgba(218, 143, 255, 0.8)
}

@media (min-width: 500px) {
  .experience-page {
    font-size: 2.4vmin;
  }
  .employer-name {
    font-size: 3vmin;
  }
}

@media (min-width: 768px) {
  .experience-page {
    font-size: 2.4vmin;
    padding-top: 15vmin;
  }
  .role-and-dates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .work-history-list {
    margin: 0 5vmin;
  }
}

@media (min-width: 1024px) {
  .experience-page {
    font-size: 2.8vmin;
  }
  .work-history-list {
    margin: 0 3vmin;
  }
}

@media (min-width: 1200px) {
  .work-history-list {
    margin: 0 12vmin;
  }
}
footer {
  background-color: var(--white);
  background-image: linear-gradient(135deg, var(--white) 0%, var(--off-white) 50%, rgba(85, 41, 234, 0.4) 60%, rgba(85, 41, 234, 0.4) 60%, rgba(85, 41, 234, 0.7) 70%, var(--purple-light) 85%);
  padding: 3vmin;
  display: flex;
  justify-content: flex-start;
}

body.dark footer {
  background-color: var(--dark);
  background-image: linear-gradient(135deg, var(--dark) 0%, var(--off-dark) 50%, rgba(218, 143, 255, 0.2) 60%, rgba(218, 143, 255, 0.4) 70%, rgba(218, 143, 255, 0.7) 80%, var(--purple-dark) 90%);
}

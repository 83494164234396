.home-page {
  min-height: 100vh;
  background-color: var(--white);
}

body.dark .home-page {
  background-color: var(--off-dark);
}

/* ! Title section */

.name {
  font-weight: bold;
}

.name {
  font-size: 8.5vmin;
}

.name:hover {
  transition: 1s ease;
  color: var(--purple-light);
}

body.dark .name:hover {
  color: var(--purple-dark);
}

.title-section {
  padding-top: 15vmin;
}

.title {
  font-style: italic;
}


.title-container {
  height: 83vh;
}

.scrollers {
  height: 17vh;
}

/* ! About and Contact Buttons */

.buttons-container {
  margin-top: 5vmin;
}

.about-me-button, .contact-button {
  text-decoration: none;
  color: var(--purple-light);
  background-color: var(--white);
  padding: 2.5vmin 3.7vmin;
  border: 0.3vmin solid var(--purple-light);
  border-radius: 2vmin;
  box-shadow: 5px 5px 5px -3px rgba(85, 41, 234, 0.4);
  transition: box-shadow 0.2s ease;
  font-size: 4vmin;
  font-weight: bold;
}

body.dark .about-me-button, body.dark .contact-button {
  background-color: var(--off-dark);
  color: var(--purple-dark);
  border-color: var(--purple-dark);
  box-shadow: 5px 5px 5px -3px rgba(218, 143, 255, 0.4);
}

.about-me-button:active, .contact-button:active, body.dark .about-me-button:active, body.dark .contact-button:active {
  box-shadow: none;
}

.about-me-button:hover, .contact-button:hover {
  background-color: var(--purple-light);
  color: var(--white);
}

body.dark .about-me-button:hover, body.dark .contact-button:hover, body.dark .about-me-button:hover a, body.dark .contact-button:hover a {
  color: var(--purple-dark);
  background-color: var(--purple-dark);
  color: var(--dark);
}

/* ! Contact section */

.contact-section {
  display: none;
  padding-top: 8vmin;
}

.contact-icons {
  list-style: none;
  font-size: 5vmin;
}

.contact-icon {
  color: var(--off-dark);
  padding: 1vmin;
}

body.dark .contact-icon {
  color: var(--off-white);
}

.contact-icon:hover {
  color: var(--blue-light);
  cursor: pointer;
}

body.dark .contact-icon:hover {
  color: var(--blue-dark);
}

/* ? Scrolling mouse */

.scrollers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-down {
  width: 34px;
  height: 55px;
  display: none;
}

.mouse {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid var(--off-dark);
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}

.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: var(--off-dark);
  animation-name: scroll;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}

body.dark .mouse {
  border-color: var(--off-white);
}

body.dark .scroller {
  background-color: var(--off-white);
}

@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}

.scroll-down-arrow {
  font-size: 6.5vmin;
  animation: pulse 1.5s infinite;
}

.scroll-up-arrow {
  font-size: 4.8vmin;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* ! Media Queries */

@media (min-width: 768px) {
  .title-section {
    padding-top: 15vmin;
  }
  .title-container {
    height: 80vh;
  }
  .scrollers {
    height: 20vh;
  }
  .contact-section, .scroll-down {
    display: block;
  }
  .scroll-down-arrow {
    display: none;
  }
  .about-me-button, .contact-button {
    font-size: 2.7vmin;
  }
}
/* ! Skills section */

.skills-container {
  min-height: 100vh;
  background-color: var(--white);
  padding-top: 22vmin;
}

body.dark .skills-container {
  background-color: var(--off-dark);
}

.skills-title {
  font-weight: bold;
}

.all-skills .skill {
  font-size: 10vmin;
}

.all-skills .badge {
  font-size: 4vmin;
}

.all-skills .skill:hover {
  color: var(--purple-light)
}

body.dark .all-skills .skill:hover {
  color: var(--purple-dark)
}

@media (min-width: 500px) {
  .all-skills .badge {
    font-size: 3vmin;
  }
}

@media (min-width: 768px) {
  .skills-container {
    padding-top: 15vmin;
  }
  .all-skills .badge {
    font-size: 2.5vmin;
  }
}
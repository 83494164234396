.about-page {
  min-height: 100vh;
  font-size: 4vmin;
  padding-top: 22vmin;
}

body.dark .about-page {
  background-color: var(--dark);
}

.purple-text {
  color: var(--purple-light);
}

body.dark .purple-text {
  color: var(--purple-dark);
}

@media (min-width: 768px) {
  .about-page {
    font-size: 2.8vmin;
    padding-top: 15vmin;
  }
}